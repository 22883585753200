import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <header className={`navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
        <div data-thq="thq-navbar-nav" className="navbar-desktop-menu">
          <nav className="navbar-links">
            <Link to="/teams" className="navbar-link1 thq-body-small thq-link">
              {props.link1}
            </Link>
            <Link
              to="/organization"
              className="navbar-link2 thq-body-small thq-link"
            >
              {props.link2}
            </Link>
            <Link
              to="/schedule"
              className="navbar-link3 thq-body-small thq-link"
            >
              {props.link3}
            </Link>
            <Link
              to="/donations"
              className="navbar-link4 thq-body-small thq-link"
            >
              {props.link4}
            </Link>
            <a
              href={props.linkLink5}
              target="_blank"
              rel="noreferrer noopener"
              className="navbar-link5 thq-body-small thq-link"
            >
              {props.link5}
            </a>
          </nav>
          <div className="navbar-buttons">
            <button className="navbar-action1 thq-button-animated thq-button-filled">
              <span className="thq-body-small">{props.text}</span>
            </button>
            <button className="navbar-action2 thq-button-outline thq-button-animated">
              <span className="thq-body-small">{props.text1}</span>
            </button>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbar-icon">
            <path
              d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
              className=""
            ></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div className="navbar-nav">
            <div className="navbar-top">
              <Link to="/" className="navbar-navlink">
                <img
                  alt={props.logoAlt}
                  src={props.logoSrc}
                  className="navbar-logo"
                />
              </Link>
              <div data-thq="thq-close-menu" className="navbar-close-menu">
                <svg viewBox="0 0 1024 1024" className="navbar-icon2">
                  <path
                    d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
                    className=""
                  ></path>
                </svg>
              </div>
            </div>
            <nav className="navbar-links1">
              <Link
                to="/teams"
                className="navbar-link11 thq-body-small thq-link"
              >
                {props.link11}
              </Link>
              <Link
                to="/organization"
                className="navbar-link21 thq-body-small thq-link"
              >
                {props.link21}
              </Link>
              <Link
                to="/schedule"
                className="navbar-link31 thq-body-small thq-link"
              >
                {props.link31}
              </Link>
              <Link
                to="/donations"
                className="navbar-link41 thq-body-small thq-link"
              >
                {props.link41}
              </Link>
              <a
                href={props.linkLink51}
                target="_blank"
                rel="noreferrer noopener"
                className="navbar-link51 thq-body-small thq-link"
              >
                {props.link51}
              </a>
            </nav>
          </div>
          <div className="navbar-buttons1">
            <button className="thq-button-filled">{props.action1}</button>
            <button className="thq-button-outline">{props.action2}</button>
          </div>
        </div>
      </header>
    </header>
  )
}

Navbar.defaultProps = {
  link1: 'Teams',
  link2: 'Organization',
  link3: 'Schedule',
  link4: 'Donations',
  link5: 'Shop',
  linkLink5: 'https://www.player1apparel.com/collections/team-4cez',
  action1: 'Login',
  action2: 'Register',
  text: 'Action 1',
  text1: 'Action 2',
  logoSrc: '/logo_base_transparent-1500h.png',
  logoAlt: '4ceZ Esports',
  link11: 'Teams',
  link21: 'Organization',
  link31: 'Schedule',
  link41: 'Donations',
  link51: 'Shop',
  linkLink51: 'https://www.player1apparel.com/collections/team-4cez',
  rootClassName: '',
}

Navbar.propTypes = {
  link1: PropTypes.string,
  link2: PropTypes.string,
  link3: PropTypes.string,
  link4: PropTypes.string,
  link5: PropTypes.string,
  linkLink5: PropTypes.string,
  action1: PropTypes.string,
  action2: PropTypes.string,
  text: PropTypes.string,
  text1: PropTypes.string,
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  link11: PropTypes.string,
  link21: PropTypes.string,
  link31: PropTypes.string,
  link41: PropTypes.string,
  link51: PropTypes.string,
  linkLink51: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default Navbar
