import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer1 from '../components/footer1'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>4ecZ Esports</title>
        <meta property="og:title" content="4ecZ Esports" />
      </Helmet>
      <Navbar></Navbar>
      <div className="home-header30 thq-section-padding">
        <img
          alt="Gaming Clan Image"
          src="/home_banner-1500w.png"
          className="home-image"
        />
        <div className="home-container1">
          <div className="home-max-width thq-section-max-width">
            <div className="home-content">
              <h1 className="home-text TextShadowHomeWelcome thq-heading-1">
                Welcome to 4ceZ Esports
              </h1>
              <p className="home-text1 thq-body-large">
                We are an esports organization established in 2020 that competes
                in a variety of games, including Valorant, Rainbow Six Siege,
                Apex, and more. Our ultimate goal is to assist players in
                enhancing their skills to compete at higher levels, such as tier
                3-4 level competitive experience, and beyond.
              </p>
              <div className="home-actions">
                <button className="home-button thq-button-filled">
                  <span className="home-text2 thq-body-small">Learn More</span>
                </button>
                <a
                  href="https://discord.gg/4cezesports"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-button1 thq-button-outline"
                >
                  <span className="home-text3 thq-body-small">Join Now</span>
                </a>
              </div>
            </div>
          </div>
          <div className="home-container2"></div>
        </div>
      </div>
      <div className="home-layout251 thq-section-padding">
        <div className="home-max-width1 thq-section-max-width">
          <div className="home-section-title thq-flex-row">
            <div className="home-column thq-flex-column">
              <h2 className="home-text4 TextShadowOurSponsors thq-heading-1">
                Our Sponsors
              </h2>
            </div>
          </div>
          <div className="home-content1">
            <div className="home-row thq-flex-row">
              <a
                href="https://www.xidax.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="home-feature1">
                  <img alt="image" src="/xidax_sponsor-400w.png" />
                </div>
              </a>
              <a
                href="https://www.player1apparel.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="home-feature2">
                  <img
                    alt="image"
                    src="/player1apparel-logo-400w.webp"
                    className="home-image2"
                  />
                </div>
              </a>
              <a
                href="https://wraithenergy.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="home-feature3">
                  <img
                    alt="image"
                    src="/wrath_sponsor-200h.png"
                    className="home-image3"
                  />
                </div>
              </a>
            </div>
            <div className="home-row1 thq-flex-row">
              <a
                href="https://www.player1apparel.com/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="home-feature11">
                  <img
                    alt="image"
                    src="/rogue_sponsor-200h.webp"
                    className="home-image4"
                  />
                </div>
              </a>
              <a
                href="https://twitter.com/beehivewallet?lang=en"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="home-feature21">
                  <img
                    alt="image"
                    src="/beehive_sponsor-400w.png"
                    className="home-image5"
                  />
                </div>
              </a>
              <a
                href="https://www.mixtenergy.net/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <div className="home-feature31">
                  <img
                    alt="image"
                    src="/mixt_sponsor-400w.png"
                    className="home-image6"
                  />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer1 rootClassName="footer1-root-class-name1"></Footer1>
      <Link to="/" className="home-navlink">
        <img
          alt="4ceZ Esports"
          src="/logo_base_transparent-1500h.png"
          className="home-image1 home-image1"
        />
      </Link>
    </div>
  )
}

export default Home
