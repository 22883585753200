import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer1 from '../components/footer1'
import './donations.css'

const Donations = (props) => {
  return (
    <div className="donations-container">
      <Helmet>
        <title>Donations - 4ecZ Esports</title>
        <meta property="og:title" content="Donations - 4ecZ Esports" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name3"></Navbar>
      <div className="donations-header30 thq-section-padding">
        <img
          alt="Gaming Clan Image"
          src="/home_banner-1500w.png"
          className="donations-image"
        />
        <div className="donations-container1">
          <div className="donations-max-width thq-section-max-width">
            <div className="donations-content">
              <h1 className="donations-text TextShadowHomeWelcome thq-heading-1">
                This Page is Coming Soon!
              </h1>
              <p className="donations-text1 thq-body-large">
                Our website is still currently being developed. Please feel free
                to come back later and check it out!
              </p>
              <div className="donations-actions">
                <button className="donations-button thq-button-filled">
                  <span className="donations-text2 thq-body-small">
                    Learn More
                  </span>
                </button>
                <a
                  href="https://discord.gg/4cezesports"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="donations-button1 thq-button-outline"
                >
                  <span className="donations-text3 thq-body-small">
                    Join Now
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="donations-container2"></div>
        </div>
      </div>
      <Link to="/" className="donations-navlink">
        <img
          alt="4ceZ Esports"
          src="/logo_base_transparent-200h.png"
          className="donations-image1"
        />
      </Link>
      <Footer1 rootClassName="footer1-root-class-name4"></Footer1>
    </div>
  )
}

export default Donations
