import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Footer1 from '../components/footer1'
import './teams.css'

const Teams = (props) => {
  return (
    <div className="teams-container">
      <Helmet>
        <title>Teams - 4ecZ Esports</title>
        <meta property="og:title" content="Teams - 4ecZ Esports" />
      </Helmet>
      <Navbar rootClassName="navbar-root-class-name"></Navbar>
      <div className="teams-header30 thq-section-padding">
        <img
          alt="Gaming Clan Image"
          src="/home_banner-1000h.png"
          className="teams-image"
        />
        <div className="teams-container1">
          <div className="teams-container2">
            <div className="teams-layout300 thq-section-padding">
              <h1 className="teams-text TextShadowHomeWelcome thq-heading-1">
                <span>Our Teams</span>
                <br></br>
              </h1>
              <div className="teams-max-width thq-section-max-width">
                <div className="teams-content">
                  <div className="teams-row thq-flex-row">
                    <div className="teams-feature2 hoverchangetextteam">
                      <img
                        alt="Team Competitions Image"
                        src="/bandits_team_logo.jpg"
                        className="teams-feature2-image"
                      />
                      <div className="teams-content1">
                        <h3 className="thq-heading-1">Bandits</h3>
                      </div>
                    </div>
                    <div className="teams-feature21 hoverchangetextteam">
                      <img
                        alt="Team Competitions Image"
                        src="/void_team_logo.jpg"
                        className="teams-feature2-image1"
                      />
                      <div className="teams-content2">
                        <h3 className="thq-heading-1">Void</h3>
                      </div>
                    </div>
                    <div className="teams-feature3 hoverchangetextteam">
                      <img
                        alt="Skill Development Image"
                        src="/lux_team_logo.jpg"
                        className="teams-feature3-image"
                      />
                      <div className="teams-content3">
                        <h3 className="thq-heading-1">Lux</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h1 className="teams-text3 TextShadowHomeWelcome thq-heading-1">
                <span>More Coming Soon!</span>
                <br></br>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <Footer1 rootClassName="footer1-root-class-name"></Footer1>
      <Link to="/" className="teams-navlink">
        <img
          alt="4ceZ Esports"
          src="logo_base_transparent-1500h.png"
          className="teams-image1"
        />
      </Link>
    </div>
  )
}

export default Teams
